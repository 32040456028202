.transport-mid-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 200px;
    width: 100%;
    background: url("../../../assets/img/street-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .transport-label {
    z-index: 1;
    color: #fff;
    font-family: "DejaVu Sans Bold", sans-serif;
    font-size: 26px;
  }
  .transport-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
    padding-top: 50px;
    padding-bottom: 80px;
    background-color: #f6f6f6;
  }