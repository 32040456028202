.search-field_container {
    display: flex;
    flex-direction: row;
    position: fixed;
    align-items: center;
    top: 50%;
    right: 0px;
    height: 40px;
    z-index: 996;
}
.search-field-extended {
    width: 100%;
}
.calendar-search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: rgb(66, 185, 71);
    position: absolute;
    right: 0;
    height: 90px;
    width: 42px;
    border-radius: 4px;
  }
  .calendar-search-field_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: row;
  }
  .calendar-deactivate-search-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: rgb(232, 113, 87);
    border: none;
    position: absolute;
    right: -218px;
  }