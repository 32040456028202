.review-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background: #f7f9ff;
  padding: 12px;
  border-radius: 18px;
}
.review-count {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.reviewer-name {
  
  color: #2c4251;
  font-size: 14px;
  font-weight: bold;
}
.review-content {
  color: rgb(121, 121, 121);
  font-size: 13px;
  width: 100%;
  text-overflow: ellipsis;
}
.review-stars {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.review-stars span {
  color: #42b948;
  font-size: 20px;
  line-height: 25px;
}
.review-stars span.star_dis {
  color: #bfbfbf;
  font-size: 20px;
  line-height: 25px;
}
.star-div {
  background-image: url("../../../assets/img/icons/star-off.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 15px;
  height: 15px;
  cursor: pointer;
}
.star-div:hover {
  background-image: url("../../../assets/img/icons/star-on.svg");
}
.star-active {
  background-image: url("../../../assets/img/icons/star-on.svg");
}
.timeout-div {
  display: none;
}