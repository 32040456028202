.event-category-mid_container {
  /* width: 100%;
  height: 450px;
  position: relative;
  background-image: url("../../../assets/img/kinoafisha-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
}
.event-category-mid_container .search-field {
  width: 45%;
}
.event-category-mid_container .dimmed-overlay {
  background-color: rgba(44, 66, 81, 0.4);
}
.event-category-mid_container .category-title{
  color: rgb(73, 81, 111);
}
.category-description {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  z-index: 10;
  margin-top: 20px;
}
/* #region Category Render */

.category-event-render_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* justify-content: center; */
  min-height: 600px;
  background-color: rgba(246, 246, 246);
  padding-bottom: 80px;
}
.category-event-render_filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  position: relative;
  top: 0;
}
.category-event-render_cinema-filter {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.event-price-range {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  
  color: rgb(73, 81, 111);
}
.event-filter-cinema-item {
  border-radius: 50px;
  background-color: #e6e7e95d;
  padding-top: 10px;
  font-size: 10pt;
  padding-bottom: 10px;
  margin: 5px;
  text-align: center;
  
  cursor: pointer;
  transition: all 300ms;
}
.cinema-filter--active {
  background-color: #577ee8;
  color: #fff;
}
.event-filter-cinema-item:hover {
  background-color: #577ee8;
  color: #fff;
}
.event-price-range {
}
.day-filter_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.day-filter-each {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin: 2px;
  background-color: #e6e7e9;
  height: 70px;
  width: 70px;
  
}
.day-filter-each:hover {
  cursor: pointer;
}
.day-filter-each-dayN {
  color: rgba(27, 45, 42, 0.9);
  font-size: 12px;
  line-height: normal;
}
.active .day-filter-each-dayN {
  color: rgba(255, 255, 255, 0.7);
}
.day-filter-each-month {
  font-size: 12px;
  font-weight: bold;
  margin-left: 2px;
  color: #2c4251;
}
.active .day-filter-each-month {
  color: rgba(255, 255, 255, 0.7);
}
.day-filter-each-weekday {
  display: block;
  font-size: 13px;
  line-height: normal;
  color: rgba(27, 45, 42, 0.9);
}
.active .day-filter-each-weekday {
  color: #fff;
}
.day-filter_wrapper .active {
  background-color: #577ee8;
  color: #fff !important;
}
.soon-text {
  font-size: 14px;
  color: #577ee8;
}
.category-event-render_wrapper {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  justify-content: space-between;
  vertical-align: baseline;
  width: 66%;
  margin-top: 0px;
}
/* #endregion Category Render */
