.submit-review-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.review-count-label {
  
  color: #2c4251;
  font-size: 14px;
  font-weight: bold;
}
.user-review {
  height: 50px;
  width: calc(100% - 20px);
  margin: 10px 0px 0px 0px;
  padding: 10px;
  border: none;
  resize: none;
  
  font-weight: bold;
  color: #2c4251;
  border: 1px solid #eee;
  border-radius: 8px;
}
.submit-user-review {
  height: 32px;
  width: 100%;
  margin-top: 16px;
  background-color: #49516f;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
