.day {
    cursor: pointer;
}
.month-container {
    position: relative;
}
.calendar {
    overflow: initial!important;
}   
.event-tooltip-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: calc(50% - 85px);
    width: 170px;
    min-height: 50px;
    border-radius: 4px;
    background-color: rgba(73, 81, 111, 0.89);
    z-index: 999;
    color: #FFF;
    padding: 5px;   
}
/* .calendar .months-container{
    display: block!important;
} */