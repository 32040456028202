.alert-wrapper.hidden .alert {
    display: none;
}
.alert {
    position: fixed;
    width: 80%;
    height: max-content;
    bottom: 80px;
    left: 0;
    right: 0;
    margin: auto;
    box-sizing: border-box;
    padding: 12px 14px;
    border-radius: 4px;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.alert.danger {
    color: #ffffff;
    background: #ff5722;
}
.alert.success {
    color: #ffffff;
    background: #42b947;
}
.alert.warning {
    color: #ffffff;
    background: #ff9800;
}
.alert span {
    font-size: 12px;
}
.alert button {
    border: none;
    position: relative;
    text-align: center;
    display: flex;
    align-content: center;
    border-radius: 50px;
    background: #fff;
    outline: none;
    /* width: 20px;
    height: 20px; */
    font-size: 11px;
    line-height: 17px;
    padding: 1px 6px;
}