::-webkit-input-placeholder {
  text-align: left; 
}
input:-moz-placeholder {
 text-align: left;
}
.currency-mid-section_container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  background-color: rgb(246, 246, 246);
}
tr:nth-child(odd) {
  background-color: #ffffff;
}

/* #region MidSection */
.currency-mid_third-panel_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 190px;
  background-color: rgb(73, 81, 111);
  z-index: 10;
  margin: 15px auto 0 auto;
}
.third-panel-value_container,
.third-panel-curr_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}
.third-panel-value-from,
.third-panel-value-to {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  height: 50px;
  padding: 0;
  width: 165px;
}

.swapp-icon:hover {
  cursor: pointer;
}
.third-panel-value-to {
  color: rgb(66, 185, 71);
  background-color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  text-align: center;
  
}
.third-panel-value-from,
.third-panel-value-from.third-panel-value-from::placeholder 
 {
  font-size: 24px;
  font-weight: bold;
  color: rgb(232, 113, 87);
  background-color: rgb(224, 224, 224);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: none;
  outline: none;
  text-align: center;
  
}
.third-panel-curr-from,
.third-panel-curr-to {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 135px;
  height: 40px;
  background-color: #fff;
  font-weight: bolder;
  text-align: center;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.third-panel-curr-from {
  color: rgb(232, 113, 87);
  text-align-last: center;
}
.third-panel-curr-to {
  color: rgb(66, 185, 71);
  text-align-last: center;
  
}
.swapp-icon {
  width: 20px;
  margin-top: 3px;
}
.swapp-icon img {
  width: 100%;
}
.drop-down-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 10px;
  top: 0px;
}
.curr-dropdown-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  top: 50px;
}
.from-dropdown-wrapper,
.to-dropdown-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  border-radius: 5px;
  width: 450px;
  min-height: 80px;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(73, 81, 111, 0.33);
}
.from-dropdown-wrapper {
  left: 45px;
}
.to-dropdown-wrapper {
  right: -268px;
}
.option-wrapper {
  max-height: 220px;
  width: 100%;
  overflow-y: auto;
  margin-top: 10px;
  overflow-x: hidden;
}
.option-item {
  padding: 6px 20px 6px 20px;
  text-align: left;
  transition: 300ms;
  cursor: pointer;
  line-height: 30px;
  color: #566874;
  font-size: 14px;
  margin-bottom: 10px;
}
.option-item span {
  margin-left: 10px;
  color: #566874;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
}
.option-item span:hover {
  color: #fff !important;
}
.option-item:hover {
  color: #42b948 !important;
  background-color: rgb(236, 237, 238);
}
.option-item:hover span {
  color: #42b948 !important;
}
.currency-list-options {
  display: flex;
}
.cell-currency-code-sm {
  background: #42b948;
  
  color: #fff;
  padding: 0px 4px 0px 4px;
  margin-right: 0px;
  border-radius: 4px;
  width: 40px;
  text-align: center;
}
.from-search,
.to-search {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  border-radius: 5px;
  height: 30px;
  border: 1px solid #82b7e1;
  box-shadow: #82b7e1 0px 0px 5px 0px;
}
.from-search input,
.to-search input {
  border: none;
  width: 90%;
  padding-top: 3px;
  font-size: 12px;
  
}
/* #endregion MidSection */

/* #region RenderSection */

.currency-render-section_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
  width: 100%;
  background-color: rgb(246, 246, 246);
  margin-bottom: 100px;
}
.currency-render-section_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}
.currency-inner-search_container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.currency-inner-search_wrapper {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 61px;
  z-index: 4;
  height: 35px;
  background-color: #ffffff !important;
  border-bottom: 1px solid #eee;
  border-radius: 0px;
  width: 100%;
}
.currency-inner-search-icon {
  margin-left: 12px;
  width: 22px;
}
.currency-inner-search {
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin-left: 20px;
  font-size: 12px;
  position: relative;
}
.currency-inner-div {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rgb(87, 126, 232); */
  border-radius: 25px;
  font-size: 12px;
  font-weight: bold;
  height: 100%;
  width: 80%;
  /* padding-left: 20px; */
  /* padding-right: 20px; */
}
.currency-search-grid-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cell-active {
  /* border-bottom: 2px solid rgb(87, 126, 232); */
  background-color: rgb(87, 126, 232);
  color: #fff;
}
.currency-render-section_wrapper .cell-1 {
  width: 40%;
}
.currency-render-section_wrapper .cell-2 {
  width: 20%;
}
.currency-render-section_wrapper .cell-3 {
  width: 30%;
}
.currency-render-section_wrapper .cell-4 {
  width: 15%;
}
.currency-table {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.fixed-table-currency-bar {
  position: fixed!important;
  bottom: 94px;
  z-index: 4;
  background: #fff;
  width: 100%;
}
.currency-table  .currency-table-info {
  /* margin-bottom: 20px; */
  font-size: 16px;
  background-color: rgb(246, 246, 246);
  width: 94%;
  z-index: 4;
}
.currency-table  .currency-table-content {
  width: 100%;
  z-index: 3;
  background-color: rgb(246, 246, 246);
}
.currency-table-headers {
  background-color: #fff!important;
}
.currency-table-headers,
.currency-item_wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  
  border-radius: 4px;
  position: relative;
}
.currency-table-mid {
  margin-top: 0px;
}
.buy-sell {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.currency-item_wrapper .cell-1 {
  display: flex;
  justify-content: space-between;
}
.currency-item_wrapper .cell-1 span {
  margin-left: 10px;
}
.currency-table-content {
  font-size: 12px;
}
.currency-table-headers th {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  line-height: 30px;
  border-radius: 4px;
}
.currency-table-content .cell-1 {
  justify-content: center;
}
.currency-table-content .cell-3 {
  margin-left: 10px;
}
.currency-table-info .cell-1 {
  width: 33%;
  font-size: 10pt;
  justify-content: center;
}
.currency-table-info .cell-2 {
  width: 33%;
  font-size: 10pt;
}
.currency-table-info .cell-3 {
  width: 33%;
  font-size: 10pt;
}
.currency-table-headers .cell-4 {
  width: 20%;
}
.currency-table-headers .cell-5 {
  width: 20%;
}
.currency-table-headers .cell-2,
.currency-table-headers .cell-3,
.currency-table-headers .cell-4,
.currency-table-headers .cell-5 {
  justify-content: center;
}
.currency-table-headers .buy-sell {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
}
.currency-item_wrapper td {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10pt;
}
.currency-item_wrapper .cell-1 {
  width: 40%;
  font-weight: bolder;
}
.currency-item_wrapper .cell-1 a {
  text-decoration: none;
  color: #2c4251;
  font-weight: normal;
}
.currency-item_wrapper .cell-1 a:hover {
  color: rgb(66, 185, 71);
}
.currency-item_wrapper .cell-1 span {
  text-decoration: none;
  color: #2c4251;
  font-weight: normal;
}
.currency-item_wrapper .cell-1 span:hover {
  color: rgb(66, 185, 71);
}
.currency-item_wrapper .cell-2 {
  width: 20%;
  color: rgba(33, 33, 33, 0.589);
}
.currency-item_wrapper .cell-3 {
  width: 20%;
  color: rgb(66, 185, 71);
  font-size: 14px;
}
.currency-item_wrapper .cell-4 {
  width: 20%;
}
.currency-item_wrapper .cell-5 {
  width: 15%;
}
.currency-item_wrapper .cell-2,
.currency-item_wrapper .cell-3,
.currency-item_wrapper .cell-4,
.currency-item_wrapper .cell-5 {
  justify-content: center;
  position: relative;
}
.currency-item_wrapper .buy-sell {
  font-size: 10pt;
  position: relative;
  top: 2px;
}
.currency-item_wrapper .buy {
  color: rgb(232, 113, 87);
  /* right: 15px; */
  cursor: pointer;
}
.currency-item_wrapper .sell {
  color: rgb(66, 185, 71);
  /* left: 10px; */
  cursor: pointer;
}
.currency-item_wrapper .cell-currency-code {
  /* background: #42b948; */
  
  color: rgb(126, 126, 127);
  /* padding: 6px 8px 6px 8px; */
  border-radius: 4px;
  width: 40px;
  text-align: center;
}
/* #endregion RenderSection */

/* #region CurrencyItemPage */

.currency-item-mid_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
  width: 100%;
  background-color: rgb(246, 246, 246);
}
.currency-item-mid_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 80px;
}
.currency-item-header {
  margin-left: 150px;
  font-size: 20px;
  margin-top: 100px;
}
.currency-item-content_wrapper {
  display: grid;
  grid-template-columns: 45% 55%;
  width: 100%;
}
.currency-item-content-left {
  margin-left: 150px;
}
.currency-item-content-left table {
  display: flex;
  flex-direction: column;
  width: 430px;
  text-align: left;
}
.currency-item-table-heading,
.currency-bank-item_wrapper {
  display: grid;
  grid-template-columns: 50% 25% 25%;
  color: rgb(7, 7, 7);
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.603);
}
.currency-item-table-heading th {
  font-size: 10px;
}
.currency-item-table-heading .cell-2,
.currency-item-table-heading .cell-3,
.currency-bank-item_wrapper .cell-2,
.currency-bank-item_wrapper .cell-3 {
  text-align: right;
}
.currency-bank-item_wrapper .cell-1 {
  font-size: 12px;
  color: rgb(46, 46, 46);
  font-weight: bolder;
}
.currency-bank-item_wrapper .cell-2 {
  color: rgb(232, 113, 87);
  font-size: 14px;
}
.currency-bank-item_wrapper .cell-3 {
  color: rgb(66, 185, 71);
  font-size: 14px;
}
.currency-item-content-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 0.281);
}
.currency-item-content-right span {
  margin-left: 40px;
  font-size: 20px;
  color: rgb(7, 7, 7);
  font-weight: bolder;
}
.currency-content-nav {
  display: flex;
  flex-direction: row;
  width: 45%;
  margin-top: 30px;
  margin-left: 40px;
}
.currency-content-nav-item {
  font-size: 12px;
  font-weight: bolder;
  color: rgb(204, 204, 204);
  margin-right: 50px;
  padding-bottom: 5px;
}
.currency-content-nav .item-active {
  border-bottom: 3px solid rgb(87, 126, 232);
}
.currency-content-pick-time_wrapper {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  height: 75px;
  width: 100%;
  background-color: rgb(66, 185, 71);
  margin-top: 30px;
}
.currency-content-picked-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 30px;
  height: 100%;
}
.currency-picked-day {
  color: rgb(126, 126, 127);
  font-size: 14px;
}
.currency-picked-year {
  font-size: 12px;
  color: rgb(126, 126, 127);
}
.currency-pick-time-text {
  margin-left: 30px;
  font-weight: bolder;
  color: #fff;
}
.currency-pick-time-btn {
  border: none;
  width: 20px;
  height: 22px;
  background: url("../../../assets/img/calendar-c.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: auto;
  margin-right: 30px;
}
.currency-pick-time-btn:hover {
  cursor: pointer;
}
/* #endregion CurrencyItemPage */

.load-more {
  color: #2c4251;
  padding: 8px 16px;
  text-decoration: none;
  -webkit-transition: background-color .3s;
  transition: background-color .3s;
  border: 1px solid #dedede;
  border-radius: 24px;
  font-family: DejaVu Sans Bold,sans-serif;
  font-size: 12px;
  cursor: pointer;
}
.load-more:hover {
  background-color: rgba(4,196,43,.11);
  border: 1px solid rgba(4,196,43,.11);
  color: #42b948;
}
.load_more_container {
  margin-top: 40px;
  margin-bottom: 20px;
}
.currency-item_wrapper .eye{
  width: 15px;
  position: relative;
  left: -4px;
  top: -1px;
}
.currency-popover-sell {
  display: block;
  position: absolute;
  top: 44px;
  right: 0;
  left: 0;
  transform: translateX(0%);
  border-radius: 4px;
  background-color: rgba(73, 81, 111, 0.89);
  z-index: 999;
  color: #FFF;
  padding: 5px;
  
  width: 94%;
  margin: 0 auto;
}

.currency-popover-sell th {
  font-size: 12px;
  font-weight: normal;
  border-bottom: 1px solid #eee;
  background: #5d647c;
  padding-bottom: 4px;
}

.currency-popover-sell td {
  font-size: 12px;
  font-weight: normal;
  display: table-cell;
  flex-direction: initial;
  text-align: center;
  background: #5d647c;
}