.user-mid-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 200px;
  width: 100%;
  background: url("../../../assets/img/user-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.user-label {
  z-index: 1;
  color: #fff;
  font-size: 26px;
  margin-bottom: 12px;
}
.user-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
  padding-top: 50px;
  padding-bottom: 80px;
  background-color: #f6f6f6;
}
.user-mid-section-content {
  text-align: center;
  z-index: 1;
}
.user-close-button {
  outline: none;
  color: #42B947;
  z-index: 1;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 0;
  border-radius: 24px;
  font-size: 12px;
  cursor: pointer;
}
.user-close-button:hover {
  background-color: #49516f;
  color: #fff;
}
.agree-terms {
  font-size: 12px;
  font-weight: normal;
  padding-left: 8px;
  padding-top: 3px;
}