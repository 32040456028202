.streets-mid-bg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  width: 94%;
  margin: 0 auto;
}
.streets-label {
  z-index: 1;
  color: #49516f;
  font-size: 22px;
  margin-right: auto;
  width: 100%;
  padding: 15px 0 0 0;
}
.streets-content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 600px;
  padding-top: 20px;
  padding-bottom: 80px;
  background-color: #f6f6f6;
}
.pick-place {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  /* width: 1200px; */
  width: 100%;height: 100px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}
.pick-place * {
  
}
.pick-district {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pick-country,
.pick-city,
.pick-district {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 48%;
}
.pick-country,
.pick-city,
.pick-district {
  position: absolute;
  border-top-right-radius: 0px;
}
.pick-country {
  top: 0px;
  left: 0;
}
.pick-city {
  left: 0;
  top: 45px;
}
.pick-district {
  right: 0;
  top: 0;
}
.pick-place label {
  color: #9a9a9a;
}
.country-items-container,
.city-items-container,
.district-items-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  position: relative;
}
#country-items-wrapper,
#city-items-wrapper,
#district-items-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 180px;
  height: 40px;
  /* border-radius: 50px; */
  cursor: pointer;
  position: relative;
}
#country-items-wrapper,
#city-items-wrapper {
  width: 100%;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: #42b948;
}
#district-items-wrapper {
  width: 100%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.country-items-list-wrapper,
.city-items-list-wrapper,
.district-items-list-wrapper {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 45px;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #42b948;
  border-radius: 4px;
}
.country-items-list-wrapper::-webkit-scrollbar,
.city-items-list-wrapper::-webkit-scrollbar,
.district-items-list-wrapper::-webkit-scrollbar {
  width: 3px;
}
.city-items-search,
.country-items-search,
.district-items-search {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  border-radius: 10px;
  -webkit-transition: 150ms;
  transition: 150ms;
}
.city-items-search input,
.country-items-search input,
.district-items-search input {
  width: 80%;
  height: 100%;
  border-left: none;
  border-top: none;
  border-right: none;
  font-style: italic;
  position: relative;
}
.streets-content-wrapper .list-visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.country-item,
.city-item,
.district-item {
  width: 100%;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  -webkit-transition: 150ms;
  transition: 150ms;
}
.country-item:hover,
.city-item:hover {
  color: #42b948 !important;
  background-color: rgb(236, 237, 238);
}
.country-item-name,
.city-item-name,
.district-item-name {
  margin-left: 15px;
  width: calc(100% - 60px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 14px;
}
#country-items-wrapper img,
#city-items-wrapper img,
#district-items-wrapper img {
  position: absolute;
  right: 20px;
}
#country-items-wrapper {
  color: #fff;
}
#country-items-wrapper:hover,
#city-items-wrapper:hover,
#district-items-wrapper:hover {
  background-color: #42b948;
  color: #fff;
}
#city-items-wrapper {
  color: #fff;
}
#district-items-wrapper {
  background-color: #4a516e;
  color: #fff;
}
.search-street-wraper {
  width: 94%;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
}
.search-street-wraper img {
  width: 18px;
}
.street-search-input-head {
  width: 100%;
  height: 16px;
  padding: 4px;
  position: relative;
  border: none;
  border-bottom: 1px solid #4a516e;
  margin-left: 4px;
}
.streets-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.streets-content-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.content-left-map-wrapper {
  width: 100%;
  position: relative;
}
.content-left-map {
  position: relative !important;
  width: 100%;
  height: 600px !important;
}
.content-left-gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 50%;
  border-left: 2px solid #f6e476;
  margin-top: 5px;
  margin-bottom: 5px;
  position: absolute;
}
.content-left-place-descr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fff;
  padding: 4px 8px 4px 8px;
  width: 30%;
}
.place-descr-header {
  margin-bottom: 5px;
  margin-top: 5px;
}
.descr-header-country {
  font-size: 18px;
  color: #4a516e;
  font-weight: bold;
}
.descr-time-zone {
  font-size: 13px;
  color: #9a9a9a;
}
.descr-label {
  font-size: 13px;
  color: #9a9a9a;
}
.place-descr-header,
.descr-current-time,
.descr-post-code,
.descr-phone-code {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.descr-time-zone,
.current-time,
.post-code,
.phone-code {
  /* position: absolute; */
  color: #4a516ec9;
  font-size: 12px;
  /* left: 150px; */
}
.content-left-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
  /* justify-content: center; */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 65%;
  margin-left: 10px;
}
.streets-gallery-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
}
.streets-gallery-slider .slick-list {
  width: 100%;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}
.streets-gallery-slider .slick-list .slick-slide {
  margin-right: 10px;
  width: 220px !important;
}
.streets-gallery-slider .slick-list .slick-slide div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.content-left-description {
  background-color: #fff;
  padding: 4px 20px 4px 20px;
  font-size: 14px;
}
.content-left-description p {
  color: #012038;
}
.slider-item-wrapper {
  margin-right: 10px;
}
.slider-item {
  width: 256px;
}
.content-left-slider .next-slide {
  position: absolute;
  right: 0;
  height: 40px;
  border: none;
  font-size: 18px;
  background-color: #9a9a9ab4;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: #fff;
  cursor: pointer;
  outline: none;
}
.streets-content-right {
  height: 100%;
  width: 94%;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  border-radius: 5px;
}
.content-right-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  color: #4a516e;
  width: 100%;
}
.right-head-descr {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25% 25% 25% 25%;
      grid-template-columns: 25% 25% 25% 25%;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  -ms-grid-row-align: center;
      align-items: center;
  width: 95%;
  height: 40px;
  background-color: #e5e5e5;
  z-index: 2;
}
.head-descr-left {
  margin-left: 20px;
}
.head-descr-center {
  text-align: center;
}
.head-descr-right {
  text-align: right;
  margin-right: 34px;
}
.white-circle-bg {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  position: absolute;
  left: -20px;
  background-color: #fff;
}
.content-right-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 100px;
  right: 10px;
  background-color: #4a516e;
  z-index: 3;
  cursor: pointer;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.street-search-logo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50px;
}
.content-right-search-active {
  width: 90%;
  /* justify-content: left; */
  -webkit-box-align: center;
      -ms-flex-align: center;
              -ms-grid-row-align: center;
          align-items: center;
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.street-search-input {
  width: 100%;
  padding-left: 50px;
  padding-right: 30px;
  border: none;
  background-color: transparent;
  color: #fff;
  
}
.street-search-input::-webkit-input-placeholder {
  color: #fff;
}
.street-search-input::-moz-placeholder {
  color: #fff;
}
.street-search-input:-ms-input-placeholder {
  color: #fff;
}
.street-search-input::placeholder {
  color: #fff;
}
.content-right-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  /* align-items: center; */
  width: 100%;
  position: relative;
  min-height: 520px;
  max-height: 520px;
  overflow-y: auto;
  padding-bottom: 12px;
  box-sizing: border-box;
}
.loader-container {
  width: 100%;
  height: 520px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.content-right-item-filtered {
  padding-top: 0px !important;
}
.content-right-items::-webkit-scrollbar {
  width: 0px;
}
.content-right-item {
  border-bottom: 1px solid #9a9a9a1a;
  padding: 10px 10px;
  margin: 5px 0;
  height: 54px;
  font-size: 14px;
  position: relative;
  border-radius: 4px;
}
.content-right-item--active {
  height: 100%;
  background-color: rgb(73, 81, 111);
  color: #fff;
  box-sizing: border-box;
}
.content-item-center {
  margin-right: 0;
  margin-left: auto;
  color: #42b948;
  text-align: right;
}
.content-item-right--active {
  background: transparent;
  width: 20px;
  margin-left: 10px !important;
  padding-right: 10px;
}
.street-item-basic-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.street-item-more-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 10pt;
  width: 100%;
  border-top: 1px solid #9a9a9a;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  height: max-content;
  margin-top: 10px;
  padding-top: 10px;
}
.street-item-more-info span {
  font-size: 8pt;
  color: #fff;
  padding-bottom: 2px;
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
}
.street-item-old-name {
  color: #fff !important;
  font-size: 10pt !important;
  opacity: 1 !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.street-item-zip-info {
  color: #fff !important;
  font-size: 10pt !important;
  opacity: 1 !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  border-top: 1px solid #9a9a9a;
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 0;
}
.street-item-more-btn {
  width: 18px;
  position: relative;
  right: 6px;
}
.content-item-left {
  margin-left: 0;
  margin-right: 10px;
}
.street-item-pin {
  position: absolute;
  right: 60px;
  margin-left: auto;
  margin-right: 0px;
  width: 10pt;
}
.content-item-right {
  text-align: right;
  margin-right: 0;
  margin-left: auto;
}
.item-post-code {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  color: #fff;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: max-content;
  height: 30px;
  padding: 0px 10px;
  margin-right: 0;
  margin-left: auto;
  border-radius: 4px;
  background-color: #42b948;
  cursor: pointer;
}
.item-post-code--active {
  background-color: transparent;
  width: 20px;
  margin-right: 10px;
}
.item-post-code img {
  width: 18px;
  height: 18px;
}
.item-post-code--active img {
  width: 12px;
  height: 12px;
}
.content-left-description *,
.content-right-items * {
  
}
.right-head-descr *,
.content-left-gallery * {
  
  font-size: 14px;
  font-weight: normal;
}
.overalyList {
  position: fixed;
  background: transparent;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}