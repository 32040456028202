.event-render-section_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 100px;
  background-color: #f6f6f6;
  width: 100%;
  /* min-height: 600px; */
}
/* #region Top Movies&Theaters */
.events-top-movies_container,
.events-top-theaters_container {
  width: 100%;
}
.events-top-theaters_container {
  margin-top: 25px;
}
.events-top-movies_wrapper,
.events-top-theaters_wrapper {
  width: 1160px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top-events-description {
  font-size: 50px;
  opacity: 0.3;
  color: rgb(27, 45, 42);
  line-height: 40px;
  text-align: center;
  margin: 10px 0 20px 0;
  
}
.top-events__header {
  display: flex;
  position: relative;
  justify-content: center;
  width: 94%;
}

.top-events__header span {
  font-size: 18px;
  color: #2c4251;
  
}
.events-top-items_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0px;
  margin-bottom: 0px;
}
.events-event_wrapper {
  background: #fff;
  position: relative;
  align-self: center;
  justify-self: center;
  margin: 10px;
  -webkit-transition: all .4s;
  transition: all .4s;
  border-radius: 4px;
  width: 100%;
}
.events-event_wrapper:hover {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  -webkit-transition: all .4s;
  transition: all .4s;
  box-shadow: 0 0 15px 1px rgba(0,0,0,.11);
}
.event-inner {
  padding: 4px 10px 8px 10px;
  position: relative;
  z-index: 3;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
}
.event-title {
  text-decoration: none;
  color: rgb(44, 66, 81);
  font-weight: bolder;
  font-size: 16px;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.event-destination {
  font-size: 12px;
  opacity: 0.6;
  color: rgb(44, 66, 81);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  line-height: 22px;
  display: block;
  margin-top: 0px;
  font-weight: bolder;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-poster {
  position: relative;
}
.event-poster img {
    width: 100%;
    height: 188px;
    object-fit: cover;
}
/* #endregion Top Movies&Theaters */

/* #region Event Category Page */



/* #endregion Event Category  Page */
