html {
  /* scroll-behavior: smooth; */
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f5;
  font-family: sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img {
  image-rendering: auto;
}
hr {
  width: 100%;
  color: #9a9a9a;
}
button, button:active, button:focus {
  outline: none;
}
select {
  -webkit-appearance: none;
}
input:focus {
  -webkit-appearance: none;
  border: none;
}
input::-webkit-input-placeholder[style*=hidden] {
  -webkit-transform: translateY(-40px);
          transform: translateY(-40px);
  opacity: 1;
  visibility: visible !important;
  color: #8DAA91;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.beta-version {
  font-size: 9px;
  color: red;
  padding-top: 2px;
  box-sizing: border-box;
  text-decoration: none;
}
.content-loader-container {
  display: flex;
  justify-content: center;
}
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.84);
}
::-webkit-scrollbar-thumb {
  background: rgb(73, 81, 111); 
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(58, 65, 90); 
}

/* btns */
.btn-arrow {
  width: 10px;
  height: 10px;
}

.w-100 {
  width: 100%;
}

.mb-10 {
  margin-bottom: 100px;
}

/* text */
.text-center {
  text-align: center;
}
.text-success {
  color: #42b947;
}
.text-danger {
  color: #ff5722;
}
