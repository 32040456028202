.register-window_container {
  display: flex;
  flex-direction: row;
  position: fixed;
  justify-content: center;
  width: 100%;
  z-index: 997;
  top: 0px;
  background-color: rgba(44, 66, 81, 0.6);
  height: 1200px;
}
.register-window_wrapper {
  width: 400px;
  height: max-content;
  background-color: #fff;
  position: relative;
  top: 55px;
  z-index: 999;
  box-shadow: rgba(128, 128, 128, 0.37) 0px 0px 10px 3px;
  padding: 0 0 0 0;
  border-radius: 4px;
}
.register-window {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.register-window_wrapper .form-control {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.physical-entity-register-link span {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  font-weight: normal;
}
.physical-entity-register-link {
  margin: 0px auto;
  background-color: #49516f;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 48px;
  margin-top: 10px;
}
.physical-entity-register-link:hover {
  background-color: #343d5f;
}
.physical-entity-register-link span:hover {
  cursor: pointer;
}
.register-page-title {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #2c4251;
  font-size: 14pt;
}

.register-legal-entity-form {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 10px;
}
.register-legal-entity-form .form-control {
  width: 100%;
  margin-bottom: 10px;
}
.register-legal-entity-form label,
.register-legal-entity-form input {
  font-family: BPG Mrgvlovani, sans-serif;
}

.register-legal-entity-form label {
  
  font-size: 12px;
  font-weight: bold;
  color: #2c4251;
}
.register-legal-entity-form input,
.registration-send-code {
  border-radius: 50px;
  height: 40px;
  width: 100%;
  border: 1px solid hsla(0, 0%, 50.2%, 0.315);
  background-color: #f6f6f6;
  padding-left: 20px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  outline: none;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  box-sizing: border-box;
  font-family: "DejaVu Sans Bold", sans-serif!important;
}
.registration-send-code {
  background-color: #edd658;
  font-size: 12pt;
}
.registration-code-hint {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: "BPG Mrgvlovani";
  color: #49516f;
}
::-webkit-input-placeholder,
.register-legal-entity-form input::placeholder {
  text-align: left;
  color: rgb(88, 88, 88);
  font-size: 9pt;
  position: absolute;
}
.register-window .register-button img {
  margin-left: 5px;
  margin-top: 1px;
}
.register-window .go-to-registration {
  left: 0;
}
.register-window .register-arrow-wrapper {
  transform: rotate(180deg);
}
.register-window .go-to-login span {
  -ms-transform: rotate(-90deg); /* IE 9 */
  -webkit-transform: rotate(-90deg); /* Safari 3-8 */
  transform: rotate(-90deg);
  
  text-decoration: none !important;
  color: rgb(44, 66, 81);
  margin-left: -20px;
  font-size: 12pt;
}
.go-to-login {
  left: 0;
}
.go-to-registration,
.go-to-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  text-decoration: none;
  height: 100%;
  
  text-decoration: none;
}
.go-to-registration {
  right: 0px;
  /* width: 60px; */
}
.go-to-registration .go-to-registration-span {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari 3-8 */
  transform: rotate(90deg);
  font-size: 12pt;
  margin-right: -45px;
  color: rgb(44, 66, 81);
  text-decoration: none !important;
}
.register-arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 20pt;
  background-color: rgb(66, 185, 72);
}
.register-arrow-wrapper img {
  width: 14pt;
  margin: 0 !important;
}
.register-window .register-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 12pt;
  color: #2c4251;
  line-height: 23px;
  background: none;
  height: 50pt;
  margin-left: auto;
}
.register-window .register-button span {
  color: #2c4251;
}
.register-window .register-button:hover {
  cursor: pointer;
}
.agreement-on-rules {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
}
.register-check-square {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18px;
  border-radius: 50px;
  height: 18px;
  border: 1px solid rgba(128, 128, 128, 0.432);
}
.register-check-square:hover {
  cursor: pointer;
}
.register-check-circle {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  background-color: rgb(66, 185, 72);
}
.agreement-on-rules a {
  color: rgb(46, 46, 46);
  text-decoration: none;
  font-family: BPG Mrgvlovani, sans-serif;
  font-size: 12px;
  margin: 0;
  margin-left: 10px;
}
.register-physical-second-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 0;
}
.register-physical-second-nav span {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 16px;
  color: #aaa;
}
.horizontal-line {
  width: 150px;
  height: 1px;
  background-color: rgba(27, 45, 42, 0.2);
}
.register-by-socials {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.register-by-fb,
.register-by-g {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 11pt;
}
.register-by-g a,
.register-by-fb a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  margin-left: auto;
  font-weight: normal;
  background: transparent;
  line-height: 22px;
  border-radius: 50px;
  padding: 8px 50px;
  color: #fff;
  margin: 4px auto 4px auto;
  
}
.register-by-fb a {
  color: #577ee8;
  border: 1px solid #577ee8;
}
.register-by-g a {
  color: rgb(232, 113, 87) !important;
  border: 1px solid #e87157;
}
.register-by-g a:hover {
  background: #fff3f3;
  border: 1px solid #f1cdc5;
}
.register-by-fb:hover,
.register-by-g:hover {
  cursor: pointer;
}
.register-by-fb img,
.register-by-g img {
  margin-right: 20px;
}
.register-by-fb {
  color: rgb(63, 81, 162);
}
.register-by-fb img {
  width: 9.3pt;
}
.register-by-g {
  color: rgb(232, 113, 87);
}
.register-by-g img {
  height: 11.5pt;
}
.register-next-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: none;
  font-size: 16pt;
  cursor: pointer;
  background: transparent;
  line-height: 22px;
  margin-right: 10px;
  margin-left: auto;
  margin-bottom: 10px;
  color: rgb(46, 46, 46);
  font-weight: bolder;
  
  outline: none;
  text-decoration: none;
}
.register-prev-page img {
  transform: rotate(180deg);
}
.register-next-page img,
.register-button img,
.register-prev-page img {
  width: 14pt;
  margin-left: 5px;
}

.register-2--footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 90%;
  margin: 0 auto;
}
.register-2--footer * {
  outline: none;
}
/* #region Login */
.auth-error {
  color: #c52020;
  font-size: 12px;
  font-weight: bold;
  
  text-align: center;
  padding: 5px 5px 0px 5px;
}
.auth-warring {
  color: #ff9800;
  font-size: 12px;
  font-weight: bold;
  
  text-align: center;
  padding: 5px 5px 0px 5px;
}
.login-separator {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.login-or {
  position: relative;
  color: #aaa;
  margin-top: 20px;
  margin-bottom: 2px;
  padding-top: 10px;
  padding-bottom: 10px;
  
}
.hr-or {
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.span-or {
  display: block;
  position: absolute;
  left: 50%;
  top: -2px;
  margin-left: -25px;
  background-color: #fff;
  width: 50px;
  text-align: center;
  font-size: 14pt;
}
.login-window_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
}
.login-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100px;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
  background-color: whitesmoke;
}
.login-header-text {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2c4251;
  font-size: 14pt;
  font-weight: bolder;
  justify-content: center;
}
.login-window_wrapper .form-control {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  width: 90%;
  margin: 0 auto;
  margin-top: 5px;
  margin-bottom: 5px;
}
#login-email,
#login-password {
  /* border-radius: 50px; */
  padding-left: 15px;
  width: 100%;
  height: 40px;
}
.login-header .main__header__navigation__logo img {
  width: 70px;
}
.login-window_wrapper .login-header-text span {
  color: #2c4251;
  font-size: 14pt;
  font-weight: bolder;
}
.login-registartion-content {
  margin-left: 5px;
  margin-right: 5px;
}
.login-registartion-content a {
  color: #42b947;
}
.login-greeting {
  text-align: center;
  
}
.login-greeting h2 {
  font-size: 26px;
}
.login-greeting h4 {
  font-size: 14px;
}
.login-registartion-content {
  
  font-size: 12pt;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin-top: 30px; */
}
.login-form label {
  
  font-size: 12px;
  font-weight: bold;
  color: #2c4251;
}
.login-form input {
  border-radius: 4px;
  height: 30pt;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.315);
  background-color: rgb(246, 246, 246);
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  outline: none;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  
  box-sizing: border-box;
}
.login-form input::placeholder {
  text-align: left;
  color: rgb(88, 88, 88);
  font-size: 9pt;
}
.login-window_footer {
  display: flex;
  flex-direction: column;
  position: relative;
  /* bottom: 10px; */
  justify-content: right;
  align-items: center;
  width: 100%;
  text-align: center;
}
.login-window_wrapper .sign-in {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 14px;
  line-height: 22px;
  
  background: #42b947;
  color: #fff;
  line-height: 22px;
  padding: 8px 50px;
  margin: 10px auto 4px;
  border-radius: 25px;
}
.login-window_wrapper .sign-in img {
  width: 11pt;
}
.login-window_close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 15px;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  border: 1px solid #42b947;
  background-color: #fff;
  text-decoration: none;
  outline: none;
  margin: 10px 0px;
}
.login-window_close img {
  width: 10px;
}

/* #endregion Login */
