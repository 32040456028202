input:focus {
  outline: none;;
}
.active-nav {
  background-color: #42b947;
}
.active-nav span {
  color: #fff;
}
.main__header {
  width: 94%;
  margin: 0 auto;
  height: 50pt;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "archyedt-bold" !important;
}
.main__header__navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  position: fixed;
  bottom: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
  z-index: 9999;
  height: 46pt;
  background-color: #fff;
  overflow-y: scroll;
}
.main__header__navigation__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 0 auto;
}
.main__header__navigation__logo__content {
  background: transparent;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}
.main__header__navigation__logo img {
  width: 48px;
}
.main__header__navigation__back {
  width: 88px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.main__header__navigation__register {
  width: 88px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}
.main__header__navigation__register .register-link {
  background: transparent;
  text-align: right;
  margin: 0;
  border: 0;
  text-decoration: none;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}
.main__header__navigation__register .register-link span {
  margin-left: 6px;
  letter-spacing: 0.5px;
}
.main__header__navigation__register .register-link img {
  width: 22px;
}
.header__navigation__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.main__header__navigation::-webkit-scrollbar {
  height: 2px;
  display: none;
}
.navigation__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  margin: 0;
  border: 0;
  text-decoration: none;
  color: #012038;
  height: 100%;
  width: 100%;
  border-radius: 0px;
}

.navigation__item .navigation__item__organizations:before  {
  content: "";
  background-image: url("../../assets/img/menu-icons/home-b.png");
  background-position: left;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}
.navigation__item__organizations:before ,
.navigation__item__events:before,
.navigation__item__transport:before,
.navigation__item__currency:before,
.navigation__item__locations:before,
.navigation__item__calendar:before,
.navigation__item__profile:before
 {
  position: absolute;
  top: 15px;
  left: calc(50% - 12px);
  background-size: 100%;
}
.navigation__item.active .navigation__item__organizations:before  {
  background-image: url("../../assets/img/menu-icons/home-g.png");
}
.navigation__item .navigation__item__events:before  {
  content: "";
  background-image: url("../../assets/img/menu-icons/theater-b.png");
  background-position: left;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  
}
.navigation__item.active .navigation__item__events:before  {
  background-image: url("../../assets/img/menu-icons/theater-g.png");
}
.navigation__item .navigation__item__transport:before  {
  content: "";
  background-image: url("../../assets/img/menu-icons/transport-b.png");
  background-position: left;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}
.navigation__item.active .navigation__item__transport:before  {
  background-image: url("../../assets/img/menu-icons/transport-g.png");
}
.navigation__item .navigation__item__currency:before  {
  content: "";
  background-image: url("../../assets/img/menu-icons/valuta-b.png");
  background-position: left;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}
.navigation__item.active .navigation__item__currency:before  {
  background-image: url("../../assets/img/menu-icons/valuta-g.png");
}
.navigation__item .navigation__item__locations:before  {
  content: "";
  background-image: url("../../assets/img/menu-icons/streets-b.png");
  background-position: left;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}
.navigation__item.active .navigation__item__locations:before  {
  background-image: url("../../assets/img/menu-icons/streets-g.png");
}
.navigation__item .navigation__item__calendar:before  {
  content: "";
  background-image: url("../../assets/img/menu-icons/calendar-b.png");
  background-position: left;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
}
.navigation__item.active .navigation__item__calendar:before  {
  background-image: url("../../assets/img/menu-icons/calendar-g.png");
}
.main__header__navigation .navigation__item span {
  letter-spacing: 0.1px;
  font-size: 8px;
  font-weight: bold;
  line-height: 100px;
  height: 100%;
  position: relative;
}
.navigation__item span {
  letter-spacing: 0.1px;
  font-size: 11pt;
  line-height: 30px;
  position: relative;
}
.navigation__item__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 5px;
}
.navigation__item.active{
  background-color: transparent;
  color: #42b947!important;
}
.mid__navigation__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  margin-bottom: 5px;
}
.mid__navigation__list .mid__navigation__item__wrapper {
  width: 144px;
  height: 127px;
  background: white;
  border-radius: 2px;
  margin: 5px 5px 5px 5px;
  box-shadow: 0 0 10px 0 rgba(183,192,206,.2);
}
.main__page__container .mid__navigation__item__wrapper .more {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  height: 10px;
}
.main__page__container .mid__navigation__item__wrapper .more--active {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.mid__navigation__item__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 11.111%;
  height: 85px;
}
.mid__navigation__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0;
  border: 0;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #000;
  padding: 17px 0 0;
}
.mid__navigation__item img {
  width: 60px;
}
.mid__navigation__list .mid__navigation__item__wrapper span {
  font-size: 14px;
  padding-bottom: 15px;
  
}
.mid__navigation__item span {
  font-size: 14px;
  line-height: 17px;
  padding-top: 15px;
}
.header__logo__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 0px;
  width: 152px;
  height: 180px;
  border-radius: 0 0 100px 100px;
  background-color: #fff;
}
.header__logo__wrapper__bot {
  width: 100%;
  height: 80px;
  bottom: -35px;
  border-radius: 0 0 100px 100px;
  position: relative;
  background-color: #fff;
  z-index: 998;
  box-shadow: 0 12px 10px 0 rgba(0, 0, 0, 0.08);
}
.header__logo {
  height: 48px;
  width: 48px;
  margin-top: -10px;
  margin-bottom: 10px;
  z-index: 999;
  -webkit-transition: all 0s;
  transition: all 0s;
}

.main__page__container {
  margin-bottom: 80px;
}
.midSection__container {
  width: 100%;
  position: relative;
  margin-bottom: 12px;
  background-color:rgb(246,246,246);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: rgb(46, 46, 46);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.midSection__first-panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 20px;
  height: auto;
  position: relative;
}
.registration-link,
.language-switch {
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 999;
}
.language-switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 88px;
}
.language-switch .switch {
  margin-left: 10px;
  margin-right: 10px;
}
.language-switch span {
  font-size: 10px;
}
.language-switch label {
  font-size: 10px;
}
.language-switch .slider {
  background-color: transparent;
  border: 1px solid #808080;
}
.dimmed-overlay {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.47);
  /* background: linear-gradient(to bottom, #000000 0%, rgba(43, 25, 25, 0) 100%); */
}
.registration-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  color: #fff;
  margin-left: 30px;
}
.registration-link span {
  width: 100%;
  font-size: 12px;
}
.registration-link img {
  margin-right: 10px;
}
.switch--off {
  left: 1px;
  background-color: grey;
}
.switch--on {
  left: 24px;
  background-color: green;
}
.midSection__search-panel {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start
}
.midSection__search-panel .category-title {
  font-weight: normal !important;
  
  margin-top: 0px;
  margin-bottom: 0px;
}
.midSection__search-panel span {
  font-size: 22px;
  font-weight: normal;
  z-index: 10;
  color: #49516f;
}
.midSection__search-panel .live-search-item-name {
  font-size: 22px;
  font-weight: normal;
  z-index: 10;
  color: #49516f;
  font-weight: bold;
}
.search-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  width: 100%;
  height: 30pt;
  border-radius: 4px;
  background-color: #fff;
  z-index: 202;
  box-sizing: border-box;
}
#search-field-orgName,
#search-field-orgPlace {
  border-radius: 0;
  width: 85%;
  margin-right: 0;
  margin-left: auto;
  height: 30pt;
  background-color: #fff;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border: none;
  padding: 0;
  padding-left: 49px;
  margin-top: 12px;
  margin-bottom: 6px;
  position: relative;
}
#city-select-orgCity {
  margin-right: 0;
  margin-left: auto;
  height: 30pt;
  width: 78%;
  padding-left: 20px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
#search-field-orgName {
  border-right: 1px solid #eee;
}
.search-field-wrapper .horizontal-div {
  height: 100%;
  width: 150px;
}
.city-select {
  height: 30pt;
  border: 1px solid #fff;
  box-sizing: border-box;
  
  outline: none;
  padding-left: 10px;
  color: #717171;
  background: #fff;
}
.search-field * {
  z-index: 250 !important;
}
.search-field input {
  width: 70%;
  border: none;
  font-size: 14px;
  background-color: #fff;
  outline: none;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
}
.search-field input::-webkit-input-placeholder {
  text-align: left;
}
.search-field input::-moz-placeholder {
  text-align: left;
}
.search-field input:-ms-input-placeholder {
  text-align: left;
}
.search-field input::-ms-input-placeholder {
  text-align: left;
}
.search-field input::-webkit-input-placeholder {
  text-align: left;
}
.search-field input::-moz-placeholder {
  text-align: left;
}
.search-field input:-ms-input-placeholder {
  text-align: left;
}
.search-field input::placeholder {
  text-align: left;
}
.search-field button {
  border: none;
  background: none;
  border-radius: 4px;
}
.search-field-submit img {
 width: 22px;
 height: 22px;
}
.search-field .filter img {
  width: 16px;
  height: 16px;
}
.live-search-item-info {
  width: calc(100% - 50px);
  text-align: left;
}
.live-search-content {
  background: #fff;
  height: 280px;
  position: absolute;
  top: 48px;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  box-shadow: inset 0 15px 50px -15px rgba(38,38,42,.05), 0 10px 20px rgba(0,0,0,.14);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.live-search-item p {
  font-size: 11px;
  
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.live-search-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.live-search-content ul li a {
  color: #49516f;
  
  text-decoration: none;
}
.live-search-content .organization-title {
  margin-bottom: 12px;
}
.bookmark-btn {
  height: 20px;
  width: 16px;
  -webkit-transition: 300ms;
  transition: 300ms;
}
.live-search-content ul li p {
  color: #012038;
  text-decoration: none;
  font-size: 12px;
  
  padding-left: 30px;
}

.midSection__navigation__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.navigation__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: 130px;
  margin-bottom: -100px;
  background-color: #f6f6f6;
}
.navigation__list .navigation__item__wrapper {
  width: 165px;
  height: 100%;
  border-left: 1px solid rgba(73, 81, 111, 0.1);
  border-top: 1px solid rgba(73, 81, 111, 0.1);
  border-bottom: 1px solid rgba(73, 81, 111, 0.1);
}
.navigation__list .navigation__item__wrapper span {
  font-size: 14px;
  padding-bottom: 15px;
  
  font-weight: bold;
}
.organization-render__container {
  background-color: #f5f5f5;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 30px;
}
.organization-render__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  width: 90%;
}

.organization-render__header span {
  font-weight: bold;
  font-size: 14px;
  color: #49516f;
  letter-spacing: 0.5px;
  margin-bottom: 0;
  text-align: left; 
}
.organization-render__wrapper {
  -webkit-box-align: center;
      -ms-flex-align: center;
          -ms-grid-row-align: center;
      align-items: center;
  margin-top: 10px;
  margin-bottom: 0;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  vertical-align: baseline;
  box-sizing: border-box;
}
.main__page__container .organization__wrapper {
  border-radius: 4px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.search-field-container {
  width: 100%;
  margin-left: 6px;
  -webkit-transition: 300ms;
  transition: 300ms;
  position: relative;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.search-field-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.live-search-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 128px;
  width: 100%;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}
.live-search-wrapper::-webkit-scrollbar {
  width: 3px;
}
.live-search-wrapper::-webkit-scrollbar-track {
  background:transparent;
}
.search-checkbox-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* margin-top: -20px; */
  padding-left: 80px;
  top: -21px;
  position: relative;
  box-sizing: border-box;
  position: absolute;
}
.search-checkbox-content label {
  
  font-size: 12px;
}
.search-checkbox-content .search-checkbox {
  width: 12px;
  height: 12px;
  border-radius: 50px;
}
.live-search-item {
  color: #49516f;
  padding: 7px 0px 7px 10px;
  -webkit-transition: 300ms;
  transition: 300ms;
  z-index: 250;
  font-size: 14px;
  text-decoration: none;
  
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}
.live-search-item:focus {
  background-color: #f1f1f1;
  color: #49516f;
  cursor: pointer;
}
.live-search-item img {
  margin-right: 8px;
  position: relative;
  top: 8px;
  width: auto;
  height: 21px;
}
.organization__wrapper {
  background-color: #fff;
  margin: 10px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 4px;
}

.organization-address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding-right: 10px;
  padding-left: 10px;
}
.organization-address span {
  color: #fff !important;
  font-size: 13px !important;
  margin-bottom: 0 !important;
  font-weight: normal !important;
  margin-left: 8px;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.organization-rating-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.organization-rating-content__single {
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 21px;
}
.organization-open-hour-content {

}
.organization-open-hour-content__single {
  padding: 0;
  /* border-radius: 4px;
  background: #fff;
  margin-bottom: 21px; */
}
.organization-page__open-hour-head-title span{
    font-weight: bold;
    font-size: 14px;
    color: #49516f;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
    text-align: left;
}
.working-hour-wrapper {
  padding-bottom: 16px;
  padding-top: 16px;
}
.working-hour-wrapper tbody {
  font-size: 14px;
}
.organization-rating {
  font-size: 12px;
  margin-right: 5px;
}
.organization-rating-stars {
  color: #42b948;
  font-size: 32px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.organization-rating-stars .star_dis {
  color: #bfbfbf;
}
.organization-rating-stars.star-single {
  font-size: 20px;
}
.organization-rating-content__single .rating > label {
  width: 32px;
  height: 32px;
  background-size: 26px 26px;
}
.organization-rating-content__single .rating > input:checked ~ label, 
.organization-rating-content__single .rating:not(:checked) > label:hover, 
.organization-rating-content__single .rating:not(:checked) > label:hover ~ label {
  width: 32px;
  height: 32px;
  background-size: 26px 26px;
}
.organization-address img {
  height: 20px !important;
  position: relative;
  top: -2px;
}
.organization__header__wrapper {
  padding: 14px 14px 14px 14px;
  position: relative;
}
.organization__header__wrapper .organization-description.out {
  padding: 5px 0px;
}
.organization__header__wrapper__single {
  padding: 0;
}
.organization__header__wrapper .organization-title img {
  width: 20px;
  height: 100%;
}
.organization-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.organization-name {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  width: 100%;
  color: rgb(44, 66, 81);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}
.single-organization-name {
  font-size: 16px;
  white-space: normal;
  font-weight: bold;
}
.organization-name_wrapper{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  margin-bottom: 18px;
}
.organization__header__wrapper__single .organization-name_wrapper img {
  width: auto;
  height: 20px;
}
.org-share-btn {
  margin-right: 0;
  background: none;
  margin-left: auto;
  outline: none;
  border: none;
}
.org-share-btn:hover {
  cursor: pointer;
}
.org-share-btn img {
  width: 17px;
  height: 20.4px;
}
.socials-share_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  right: -70px;
  width: 64px;
  height: 140px;
  border: 2px solid rgba(73, 81, 111, 0.1);
  border-radius: 5px;
  background-color: #fff;
}
.socials-share-ico {
  width: 30px;
  height: 30px
}
.socials-share-ico:hover {
  cursor: pointer;
}
.filter-each {
  font-size: 12px;
}
.filter-all-btn {
  font-size: 12px;
}
.organization-description {
  font-size: 12px;
  color: #7b838e;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.organization-image__wrapper {
  position: relative;
  background-color: rgba(128, 128, 128, 0.863);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.organization-image {
  background: -webkit-linear-gradient(
    270deg,
    rgba(44, 66, 81, 0.08) 0%,
    rgba(44, 66, 81, 0.84) 100%
  );
  width: 100%;
  height: 200px;
}
.organization__footer__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 30px;
  padding: 10px 10px 10px 10px;
  box-sizing: border-box;
  height: 45px;
}
.organization-working-hours {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.organization__footer__wrapper .organization-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.call-icon__wrapper {
  /* border-left: 1px solid grey; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.organization__footer__wrapper  .organization-menu-icon {
  margin-right: 10px;
  margin-left: 10px;
  width: 13px;
}
.organization__footer__wrapper  .organization-box-icon {
  margin-right: 10px;
  margin-left: 10px;
  width: 18px;
}
.organization__footer__wrapper  .organization-phone-icon {
  margin-right: 10px;
  margin-left: 10px;
  width: 18px;
  padding-left: 10px;
  border-left: 1px solid grey;
}
.organization__footer__wrapper img {
  width: 100%;
}
.organization__footer__wrapper img:hover {
  cursor: pointer;
}
.midSection__first-panel span {
  color: #fff;
}
.display-none {
  display: none;
}
.organization-page__wrapper {
  background-color: #f5f5f5;
  width: 100%;
}
.organization-page__header-navigation {
  position: fixed;
  margin: 0 auto;
  width: 96%;
  left: 0;
  right: 0;
  padding: 12px 10px;
  display: flex;
  z-index: 9999;
  box-sizing: border-box;
  background-color: transparent;
  transition: width 0.2s 0.1s, background-color 0.2s;
}
.organization-page__header-navigation.active {
  width: 100%;
  background-color: #42b947;
  transition: width 0.1s, background-color 0.4s 0.2s;
}
.organization-page__header-navigation-left {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.organization-page__header-navigation-right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.organization-page__header-navigation .organization-page__header-navigation-item {
  display: flex;
  align-items: center;
}
.organization-page__header-navigation .organization-page__header-navigation-item img {
  width: 18px;
}
.organization-page__header-navigation .organization-page__header-navigation-item.options {
  margin-left: 24px;
}
.organization-page_header-img-wrapper {
  position: relative;
  height: 350px;
  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}
.organization-page_header-img-wrapper img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}
.organization-page__mid__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  width: 100%;
  height: 316px;
}
.organization-page__mid-back {
  width: 33.33%;
}
.organization-page__back_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.overlay-left {
  width: 33%;
  position: absolute;
  left: 0;
  z-index: 100;
}
.overlay-right {
  width: 33%;
  position: absolute;
  float: right;
}
.website-logo__wrapper {
  position: absolute;
  width: 100%;
  height: 100px;
  top: 0;
  z-index: 999;
}
.website-logo {
  background-image: url("../../assets/img/logo.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  margin-top: 10px;
  width: 48px;
  height: 48px;
  z-index: 100;
}
.mid-back-mid {
  background-repeat: no-repeat;
  background-size: cover;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}
.mid-back-left,
.mid-back-right {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
.organization-page__gallery__wrapper {
  margin-bottom: 20px;
}
.organization-page__second-mid__wrapper {
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 0px;
  margin-top: -80px;
  border-bottom: 1px solid #eee;
}
.organization-page__second-mid__wrapper.gallery-show {
  margin-top: 0px;
}
.organization-page__info__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 66%;
  z-index: 100;
  margin: 0 auto;
}
.organization-service-info_wrapper {
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  background: #fff;
  border-radius: 4px;
}
.organization-service-menu,
.organization-service-delivery,
.organization-service-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: none;
  border: none;
  font-weight: bolder;
  text-decoration: none;
}
.organization-service-delivery span {
  font-size: 12px;
  color: rgb(44, 66, 81);
  padding-left: 6px;
}
.horizontal-line {
  height: 100%;
  width: 1px;
  background-color: rgba(128, 128, 128, 0.445);
}
.vertical-line {
  width: 1px;
  height: 24px;
  background-color: rgba(27, 45, 42, 0.2);
}
.organization-service-menu:hover,
.organization-service-delivery:hover {
  cursor: pointer;
} 
.organization-service-menu span {
  font-size: 12px;
  color: rgb(44, 66, 81);
  padding-left: 6px;
}
.organization-page__profile,
.organization-page__info {
  padding: 26px 20px 20px 20px;
  background-color: #fff;
  border-radius: 4px;
}
.see-org-details--btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 14px 0px 0px;
}
.see-org-details--btn span {
  margin-left: 10px;
  font-size: 14px;
}
.see-org-details--btn .more-icon {
  height: 18px;
  margin-left: 1px;
  position: relative;
  top: 1px;
}
.right-arrow-green {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  width: 10px;
  margin-left: auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.down-arrow-green {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  width: 10px;
  margin-left: auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.organization-page__profile .oganization-name {
  font-size: 20px;
  color: #2c4251;
}
.organization-page__profile .organization-description {
  /* margin-bottom: 20px; */
  font-size: 12px;
  color: #7b838e;
}
.organization-page__profile .organization-rating {
  font-size: 12px;
  color: #2c4251;
}
.organization-page__profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 20px;
}
.organization-page__profile__content {
  width: 100%;
  padding-right: 5px;
}
.organization-profile-contact {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 20px;
  padding-bottom: 0;
}
.organization-profile-mobile,
.organization-profile-email,
.organization-profile-webpage,
.organization-profile-facebook {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 16px;
}
.organization-profile-email img {
  position: relative;
  top: 2px;
}
.organization-profile-email a, .organization-profile-mobile a span, .organization-profile-webpage a {
  font-size: 14px;
  color: #2c4251;
  font-weight: bolder;
  text-decoration: none;
}
.organization-profile-facebook img {
  position: relative;
  top: 1px;
  width: 18px;
}
.organization-profile-mobile img {
  padding: 0px;
  margin-right: 8px;
}
.organization-profile-mobile a {
  text-decoration: none;
}
.organization-profile-mobile a span {
  font-size: 14px;
  color: #2c4251;
  font-weight: bolder;
  
}
.organizaion-profile-location__wrapper {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.organization-profile-location {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* justify-content: space-around; */
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  background-color: #42b947;
  width: max-content;
  height: auto;
  padding: 4px 10px;
  z-index: 3;
  left: 0;
  bottom: 30px;
  color: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.organization-profile-location a {
  margin-left: 10px;
  font-family: "BPG Mrgvlovani", sans-serif;
  font-size: 12px;
  color: #fff;
  text-decoration: none;
}
.organization-profile-location span {
  margin-left: 10px;
  font-size: 12px;
}
.organization-profile-location img {
  width: auto;
  height: 26px;
}
.google-map-content {
  width: 100%;
  height: 320px;
  position: relative;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}
.organization-profile-direction {
  padding: 0px 20px;
  background: #fff;
  height: max-content;
  box-sizing: border-box;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.organization-profile-direction-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0px;
}
.organization-profile-direction-list span {
  font-size: 14px;
}
.organization-profile-direction-list img {
  width: auto;
  height: 20px;
  margin-right: 5px;
}
.organization-profile-working-hours__wrapper {
  width: 100%;
  display: flex;
  padding: 0px 0 0 0;
  flex-direction: row;
  justify-content: space-between;
}
.working-hours-header {
  font-size: 14px;
  font-weight: normal;
  color: #2c4251;
  
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.working-hours-header img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
    position: relative;
    bottom: 1px;
}
.working-hours {
  font-size: 12px;
  color: #2c4251;
  font-weight: bold;
  
  margin-left: 24px;
}
.vert-line {
  width: 1px;
  background: #49516f;
  margin-left: 5px;
  margin-right: 5px;
}
.organization-page__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  padding: 0;
  margin-top: 20px;
}
.organization-page__info__blocks {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.organization-page__info__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  width: 50%;
  margin-bottom: 18px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.organization-page__info__content__mobile {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          -ms-grid-row-align: baseline;
      align-items: baseline;
}
.organization-page__info__content__mobile .corresponding-info__wrapper {
  margin-left: 27px;
}
.info-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 12px;
  color: #2c4251;
  font-weight: normal;
  
  margin-right: 5px;
}
.info-header img{
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.corresponding-info {
  font-size: 12px;
  color: #2c4251;
  font-weight: bold;
}
.organization-page__similar-orgs__wrapper {
  margin: 0 auto;
  margin-bottom: 80px;
}
.organization-description_wrapper {
  display: block;
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #eee;
}
.organization-description-label {
  font-size: 14px;
  padding-bottom: 10px;
  
}
.organization-page-paragraph {
  width: 100%;
  color: rgb(121, 121, 121);
  font-size: 14px; 
}
.organization-page_review_wrapper {
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow-y: scroll;
  max-height: 300px;
}
.organization-page_review_inner {
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          -ms-grid-row-align: center;
      align-items: center;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  height: max-content;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 10px;
}
.organization-page_review_inner::-webkit-scrollbar {
  width: 3px;
}
.organization-page_review_inner .empty-reviews p {
  margin: 0;
  font-size: 12px;
}
.organization-page_review_btn {
  position: relative;
}
.organization-page_review_btn .additional-nav-row__btn__wrapper {
  top: 1px;
}
.organization-page_review_btn .additional-nav-row__btn span {
  margin-bottom: 0 !important;
}
.organization-page_review_wrapper {
  margin-bottom: 20px;
  background: #fff;
  padding: 20px;
}
.organization-page__similar-orgs__wrapper .organization-page__similar-head-title {
  font-weight: bold;
  font-size: 14px;
  color: #49516f;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  text-align: left;
}
.organization-page__similar-orgs {
  display: -ms-grid;
  display: grid;
  width: 100%;
  margin-top: 20px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 20px 20px 0px;
  box-sizing: border-box;
}
.organization-page__similar-orgs .organization__wrapper {
  width: auto;
}
.footer__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgb(73, 81, 111);
  width: 100%;
}
.footer-content__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 1200px;
  padding: 60px 0;
}
.footer-info__wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto auto auto;
  grid-template-columns: auto auto auto auto;
  width: 75%;
}
.footer-info-header,
.footer-download-app {
  font-size: 14px;
  color: #ffffff;
  
}
.footer-download-app {
  width: 25%;
}
.footer-info-list {
  margin-top: 10px;
  list-style: none;
  padding: 0;
}
.footer-info-list a {
  text-decoration: none;
  color: #e0e0e0;
  line-height: 23px;
}
.footer-info-list a:hover {
  color: rgb(66, 185, 71);
}
.footer-info-list a li {
  font-size: 12px;
  line-height: 26px;
  
}
.footer-download-app-icons.app-store {
  margin-right: 12px;
}
.footer-download-app-icons.play-store {
  margin-right: 12px;
}
.footer-download-app-icons img {
  background: #e0e0e0;
  padding: 11px 18px 10px 18px;
  border-radius: 30px;
}
.footer-download-app-icons:hover img {
  background: #d0d0d0;
}
.footer-download-app {
  text-align: left;
  display: block;
  color: rgb(27, 45, 42);
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}
.mid-section__additional-nav {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  box-sizing: border-box;
  width: 92%;
  background-color: #fff;
  top: 116px;
  z-index: 3;
  margin: 0 auto;
  border-top: none;
  box-shadow: 0 0 10px 0 rgba(183,192,206,.2);
  border-radius: 4px;
}

.mid-section__additional-nav:before,
.mid-section__additional-nav:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 7px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
}
.mid-section__additional-nav:after {
  top: -14px;
  right: 72px;
  border-bottom-color: #fff;
}
.mid-section__additional-nav:before {
  top: -14px;
  right: 72px;
  border-bottom-color: rgba(0,0,0,.15);
}

.additional-nav-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.additional-nav-row a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  height: 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #012038;
  text-align: center;
  font-size: 14px;
  /* border: 1px solid rgba(73, 81, 111, 0.1); */
  /* border-bottom: 1px solid rgba(73, 81, 111, 0.1); */
  -webkit-transition: 300ms;
  transition: 300ms;
  text-decoration: none;
  
  font-weight: normal;
  border-radius: 4px;
}
.additional-nav-row a:after {
  content: "";
  background: rgba(73, 81, 111, 0.1);
  position: absolute;
  bottom: 0;
  left: 5%;
  height: 1px;
  width: 90%;
}
.midSection__secondary__wrapper {
  /* border: 1px solid rgba(73, 81, 111, 0.1);
  background-color: #f6f6f6; */
  position: relative;
  -webkit-transition: 300ms;
  transition: 300ms;
}
.additional-nav-row a:hover {
  cursor: pointer;
  color: #42b947;
}
.additional-nav-row__btn__wrapper {
  position: absolute;
  width: 90px;
  top: 132px;
  right: 50%;
  -webkit-transform: translateX(46px);
          transform: translateX(46px);
  background-color: #f6f6f6;
  border-radius: 0 0 100px 100px;
  background-color: #f6f6f6;
  z-index: 201;
}
.additional-nav-row__btn {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #333;
  border: 1px solid rgba(73, 81, 111, 0.1);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 0px;
  height: 45px;
  -webkit-transition: 300ms;
  transition: 300ms;
  border-radius: 0 0 100px 100px;
  background-color: #ffffff;
  border-top: none;
  z-index: 201;
}
.additional-nav-row__btn span {
  font-size: 12px;
  font-weight: normal;
  
}
.additional-nav-row__btn:hover {
  background-color: rgba(4, 196, 43, 0.11);
  cursor: pointer;
  color: #42b947;
}
.additional-nav-row__btn img{
  position: relative;
  top: -5px;
}
.organization-render-container--wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  min-height: 600px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #f5f5f5;
}
.sticky {
  top: -50px;
  position: fixed;
}
.filter-org-all.sticky {
  background: #ffffff;
  top: 0px;
  position: fixed;
}
.additional-filters__wrapper.sticky.visible {
  top: 0;
  margin-top: 60px;
}
.see-results-button.sticky.visible {
  top: 8px;
}

@-webkit-keyframes slideInFromLeft {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  100% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  100% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}
.midSection__container,
.events-mid-section_container,
.organization-page__mid__wrapper,
.categoriesMidSection__container,
.currency-mid-section_container,
.calendar-page_container,
.app__container {  
  -webkit-animation: 0.8s ease-out slideInFromLeft;  
          animation: 0.8s ease-out slideInFromLeft;
}

/****** Style Star Rating Widget *****/
.rating {
    border: none;
    margin: 0;
    padding: 0;
}

.rating > input {
  display: none;
}
.rating > label {
  cursor: pointer;
  display: inline-block;
  background-image: url('../../assets/img/icons/star-off.svg');
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 16px;
  height: 16px;
  content: "";
  float: right;
}
.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #ffd700;
  cursor: pointer;
  display: inline-block;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 16px;
  height: 16px;
  background-image: url('../../assets/img/icons/star-on.svg');
} 
.rating > input:checked + label:hover,
.rating
> input:checked
~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
  color: #ffd700;
  display: inline-block;
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 16px;
  height: 16px;
  background-image: url('../../assets/img/icons/star-on.svg');
}
.return-to-back img {
  width: 16px;
}

.organization-list__wrapper {
  transition: all 0.2s;
  display: flex;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
  margin-top: 16px;
  margin-bottom: 0px;
}
.organization-list__header__wrapper {
  position: relative;
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  width: 100%;
}
.organization-list__header__wrapper .organization-title img {
  width: 20px;
  height: 100%;
}
.organization-list__header__wrapper .organization-description.out {
  padding: 5px 0px;
}
.organization-list-image__wrapper {
  position: relative;
  background-color: rgba(128, 128, 128, 0.863);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 80px;
  height: 60px;
  border-radius: 4px;
}
.organization-list-image {
  width: 80px;
  height: 60px;
}
.organization-list-name {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  color: rgb(44, 66, 81);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.organization-page_header-img-wrapper:before {
  background-color: transparent;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
}

.organization-page_header-img-wrapper:after {
  background-color: transparent;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  background: linear-gradient(180deg,rgba(0,0,0,.38) 0,rgba(0,0,0,.38) 3.5%,rgba(0,0,0,.379) 7%,rgba(0,0,0,.377) 10.35%,rgba(0,0,0,.375) 13.85%,rgba(0,0,0,.372) 17.35%,rgba(0,0,0,.369) 20.85%,rgba(0,0,0,.366) 24.35%,rgba(0,0,0,.364) 27.85%,rgba(0,0,0,.361) 31.35%,rgba(0,0,0,.358) 34.85%,rgba(0,0,0,.355) 38.35%,rgba(0,0,0,.353) 41.85%,rgba(0,0,0,.351) 45.35%,rgba(0,0,0,.35) 48.85%,rgba(0,0,0,.353) 52.35%,rgba(0,0,0,.36) 55.85%,rgba(0,0,0,.371) 59.35%,rgba(0,0,0,.385) 62.85%,rgba(0,0,0,.402) 66.35%,rgba(0,0,0,.42) 69.85%,rgba(0,0,0,.44) 73.35%,rgba(0,0,0,.46) 76.85%,rgba(0,0,0,.48) 80.35%,rgba(0,0,0,.498) 83.85%,rgba(0,0,0,.515) 87.35%,rgba(0,0,0,.529) 90.85%,rgba(0,0,0,.54) 94.35%,rgba(0,0,0,.547) 97.85%,rgba(0,0,0,.55));
}

.page-title-centered {
  width: 65%;
  margin: 0 auto;
}

.page-title-centered h5 {
  text-align: center;
  font-size: 16px;
  color: #49516f;
  margin-bottom: 0;
  line-height: 24px;
}
.page-title-centered p {
  text-align: center;
  font-size: 14px;
}